import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rad-examples-coming-soon"
    }}>{`R.A.D. Examples Coming Soon`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "dr-quine-medicine"
    }}>{`Dr. Quine Medicine`}</h2>
    <p>{`Here is an example of a fun litte R.A.D. Route quine:`}</p>
    <p>{`Simply running;`}</p>
    <pre><code parentName="pre" {...{}}>{`rr -r q2
`}</code></pre>
    <p>{`Will produce the source code, which is a child process that spawns, and runs it's own R.A.D. Route. It will proceed to execute and kill itself again every 2 seconds.`}</p>
    <p>{`Lets take a look at the source code from `}<strong parentName="p"><em parentName="strong">{`Dr. Quine "Medicine Man"`}</em></strong></p>
    <pre><code parentName="pre" {...{}}>{`const { exec } = require('child_process');

let exe = ()=> {exec(\`rr -r q2\`, (error, stdout, stderr) => {
    
    console.log(stdout)
    
    console.error(stderr)
    
    console.error(error)
    })}
setTimeout(() => {
    
exe()
  
}, 2000);
`}</code></pre>
    <p>{`This code imports the child_process module in node, and then runs its own R.A.D. Route. This child_process is never killed so the first one will keep its print out, while the new child_process run in the backround.`}</p>
    <h3 {...{
      "id": "check-your-activity-monitor-on-macos-or-top-on-linux-or-process-manager-on-windows"
    }}>{`Check your activity monitor on MacOS, or top on Linux, or Process Manager on Windows.`}</h3>
    <p>{`In your process monitor runner manager, you can see how this Quine, and how R.A.D. Routes itselt runs.`}</p>
    <pre><code parentName="pre" {...{}}>{`--Python=>
          Python=>
                  node=>
                        Python=>
                        Python=>
                                node=>
                                      Python=>
                                      Python=>
                                               node=>
                                                     Python=>
                                                     Python=>
                                                             node=>
                                                                   Python=>
                                                                   Python=>
                                                                           node=>
                                                                                  Python=>
                                                                                  Python=>
                                                                                          node=>...
`}</code></pre>
    <p>{`The initial Runner is written in Python, it runs another Python runner to get your R.A.D. Route, and passes that request to node to execute.`}</p>
    <p>{`In this case the node process is invoking the original Python runner which restarts this loop.`}</p>
    <p>{`This process is repeated every 2 seconds until either the computer runs out of memory, or the process is killed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      